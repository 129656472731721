// Get the token from the URL query parameters.
let queryString                         = new URLSearchParams(window.location.search);
let urlCode                             = queryString.get('code');
let action                              = queryString.get('action');
let editId                              = queryString.get('id');
let proxy                               = 'https://roadwaymovingcrm.com:8080/';
let appPath                             = '';
let apiLink                             = proxy + 'https://roadwaymovingservicesnyc.com/api/';

if (window.location.hostname === 'localhost') {
    apiLink = 'https://sites.local/forms_new/development/api/';
} else {
    appPath = '/';
    // Set apiLink for production or other environments
}

export { appPath, apiLink, urlCode, action, editId };