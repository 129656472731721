import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';
import { Navbar, Nav, Modal, Button, Form } from 'react-bootstrap';
import './App.css';
import {useState} from "react";
import {appPath, apiLink, urlCode} from "./config";

const Header = ({ handleOpenModal }) => {
    return (
        <div className="HeaderWrapper d-flex align-items-center justify-content-between">
            <div className="logoWrapper">
                <img src="https://roadwaymovingservicesnyc.com/widgets/images/laser-it/logo.png" alt="logo" />
            </div>
            <div className="navigationWrapper d-flex w-100 align-items-center justify-content-center">
                <Navbar expand="lg">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav className="d-flex align-items-center justify-content-center">
                            <Nav.Link href="#about">About Us</Nav.Link>
                            <Nav.Link href="#residential">Residential</Nav.Link>
                            <Nav.Link href="#commercial">Commercial</Nav.Link>
                            <Nav.Link href="#moving">Moving</Nav.Link>
                            <Nav.Link href="#contact" className="special-menu-item contactLink" onClick={handleOpenModal}>Contact Us</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        </div>
    );
}

const Banner = () => {
    return (
        <div className="bannerWrapper">
            <img src="https://roadwaymovingservicesnyc.com/widgets/images/laser-it/main-banner.jpg" className="w-100" alt="logo" />
        </div>
    );
}

const Boxes = () => {
    return (
        <div className="boxesWrapper d-flex align-items-start justify-content-evenly pb-5">
            <div className="groupBox">
                <img src="https://roadwaymovingservicesnyc.com/widgets/images/laser-it/image1.1.jpg" alt="Box" />
                <div className="imageTitle my-3">ABOUT US</div>
                <div className="imageSubContent">Based in Florida we offer high quality moving relocations</div>
            </div>
            <div className="groupBox">
                <img src="https://roadwaymovingservicesnyc.com/widgets/images/laser-it/image2.jpg" alt="Box" />
                <div className="imageTitle my-3">RESIDENTIAL</div>
                <div className="imageSubContent">We tailor-make all the moving and storage services based on your need</div>
            </div>
            <div className="groupBox">
                <img src="https://roadwaymovingservicesnyc.com/widgets/images/laser-it/image3.1.jpg" alt="Box" />
                <div className="imageTitle my-3">COMMERCIAL</div>
                <div className="imageSubContent">We have the expertise to help relocating offices and commercial clients</div>
            </div>
            <div className="groupBox">
                <img src="https://roadwaymovingservicesnyc.com/widgets/images/laser-it/image4.1.jpg" alt="Box" />
                <div className="imageTitle my-3">LOCAL & LONG DISTANCE</div>
                <div className="imageSubContent">No distance is a limit for us. Whether is local or cross country we’ve got you covered</div>
            </div>
        </div>
    );
}

const Footer = () => {
    return (
        <div className="FooterWrapper d-flex align-items-center justify-content-between px-4">
            <div className="textPLeft pt-3 pb-3">
                2023 Laser-IT<br />
                4750 NW 15th Ave Suite A, Fort Lauderdale, FL 3330<br />
                (786) 796-6946
            </div>
            <div className="textPRight pt-3 pb-3">
                Fully licensed and insured: NY DOT #T-38589USDOT #1854436 ICC MC-671325 FMC #028636
            </div>
        </div>
    );
}

function App() {
    const apiToken                      = process.env.REACT_APP_TOKEN;
    const [isLoading, setIsLoading]     = useState(false);
    const [submitText, setSubmitText]   = useState("");
    const [showModal, setShowModal]     = useState(false);
    const [formData, setFormData]       = useState({ fullName: '', phone: '', email: '' });
    const [isSent, setIsSent]           = useState(false);

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            setSubmitText("Submitting request. Please wait!");
            // Replace 'your/api/endpoint' with your actual API endpoint
            await axios.post(apiLink + "laser_it/contact_request/" + apiToken, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    if(response.status == 200) {
                        console.log('request', response.data.data[0]);
                        setIsSent(true);
                        setIsLoading(false);
                    } else {
                        console.error('Error getting client data: ');
                        setIsLoading(false);
                    }
                })
                .catch(error => {
                    console.error('Error getting client data: ');
                    setIsLoading(false);
                });
            // handleCloseModal();
            // Optionally, add success handling here
        } catch (error) {
            console.error(error);
            // Optionally, add error handling here
        }
    };

  return (
    <div className="App">
        <Header handleOpenModal={handleOpenModal} />
        <Banner />
        <Boxes />
        <Footer />
        <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <img
                    src="https://roadwaymovingservicesnyc.com/widgets/images/laser-it/logo.png"
                    alt="Logo"
                    style={{ width: '70px', height: '70px', marginRight: '10px', position: 'absolute', right: '50px' }}
                />
                <Modal.Title>
                    CONTACT US
                    <div className="modalSubTitle">We want to hear from you!</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isSent ? (
                    <div className="alert alert-success">
                        Thank you! You have successfully sent the request!
                    </div>
                ) : (
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Control type="text" name="fullName" className="fullName" placeholder="FULL NAME" value={formData.fullName} onChange={handleChange} required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control type="tel" name="phone" className="phoneNumber" placeholder="PHONE NUMBER" value={formData.phone} onChange={handleChange} required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control type="email" name="email" className="email" placeholder="HELLO@EMAIL.COM" value={formData.email} onChange={handleChange} required />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="submitButton mt-5">
                            Submit
                        </Button>
                    </Form>
                ) }
            </Modal.Body>
        </Modal>
        {isLoading ? (
            <div className="loader-container">
                <div className="loader"></div>
                <p className="loading-message">{submitText}</p>
            </div>
        ) : null }
    </div>
  );
}

export default App;
